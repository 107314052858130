body {
  margin: 0;
}

.site-layout-background {
  background: #fff;
}


.ant-layout, .ant-modal-content {
  .ant-btn {
    border-radius: 5px;

  }
  .ant-card {
    box-shadow: 0 0 8px rgba(164, 171, 182, 0.7);
  }
  .ant-pagination-item{
    border-radius: 5px;
  }
  .site-layout-background >.ant-table-wrapper{
    box-shadow: 0 0 8px #a4abb6;
  }
  .ant-tabs-tab{
    border-radius: 8px 8px 0 0 !important;
  }
  .ant-input, .ant-input-number, .ant-select, .ant-select-selector  {
    border-radius: 5px;
  }
}

.main-page-content {
  padding: 30px;
  margin-top: 30px;
}

.bg-blue {
  background: #eef5ff;
  border: 2px solid #c6dafc;
  border-radius: 4px;
}
.bg-blue .ant-form-item-extra {
  display: none;
}

.bg-red {
  background: #ffeeee;
  border: 2px solid #fcc6c6;
  border-radius: 4px;
}
.bg-red .ant-form-item-extra {
  display: none;
}

.bg-orange {
  background: #fffaee;
  border: 2px solid #fcebc6;
  border-radius: 4px;
}
.bg-orange .ant-form-item-extra {
  display: none;
}

.rjsf .ant-form-item-with-help .ant-form-item-explain {
  min-height: auto;
}
.rjsf .ant-form-item-extra {
  min-height: auto;
}
.rjsf .form-group.field {
  margin-bottom: 24px;
}
.rjsf .ant-row.ant-form-item {
  margin-bottom: 0;
}

.rjsf .form-group.field-boolean {
  margin-bottom: 12px;
}
.rjsf fieldset {
  padding: 12px;
  border: 1px solid #eee;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.01);
}

.rjsf > .form-group > fieldset {
  padding: 0;
  border: none;
  background-color: transparent;
}
.rjsf label[for="root-title"], .rjsf span[id="root-description"] {
  display: none;
}

.rjsf .ant-form-vertical .ant-form-item-label {
  font-weight: 600;
}

.rjsf.ant-form-vertical .form-group + div {
  display: none;
}

.detection-strategy + .panel.panel-default.panel-body {
  display: none;
}
